import {
  DocumentNode,
  NoInfer,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery as useApolloQuery,
} from '@apollo/client/index.js';
import useActiveTabPolling from '@engined/client/hooks/useActiveTabPolling.js';

export default function useQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
): QueryResult<TData, TVariables> {
  const result = useApolloQuery(query, {
    ...options,
    pollInterval: undefined,
  });

  useActiveTabPolling(options?.skip ? 0 : options?.pollInterval ?? 3 * 60 * 1000, result.refetch);

  return result;
}
