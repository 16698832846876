import { ApolloQueryResult, OperationVariables } from '@apollo/client/index.js';
import { useEffect } from 'react';

export default function useActiveTabPolling<TData, TVariables = OperationVariables>(
  interval: number,
  refetch: (variables?: Partial<TVariables>) => Promise<ApolloQueryResult<TData>>,
) {
  useEffect(() => {
    if (!interval) {
      return;
    }

    let timer = null;
    let lastFetch = Date.now();

    const trigger = async () => {
      await refetch();
      lastFetch = Date.now();
    };

    const handler = () => {
      if (document.visibilityState === 'visible') {
        // Refresh every POLL_INTERVAL
        timer = setInterval(trigger, interval);
        if (Date.now() - lastFetch > interval) {
          trigger();
        }
      } else {
        clearInterval(timer);
      }
    };

    handler();
    document.addEventListener('visibilitychange', handler);

    return () => {
      document.removeEventListener('visibilitychange', handler);
      clearInterval(timer);
    };
  }, [refetch, interval]);
}
